import React from "react";
import Layout from "../../components/Layout";
import Seo from "../../components/seo";
import ogIMage from "../../images/og.png";
import { Link } from "gatsby";
import SedinPartners from "../../components/Sedin-partners";
import ContactUsBlock from "../../components/ContactUsBlock";

const AIChapterPage = () => {
    return (
        <Layout>
            <Seo title="" description="" image={`https://sedintechnologies.com${ogIMage}`} />
            <section className="ai-chapter-hero">
                <div className="container container--first">
                    <div className="text">
                        <div className="section-tag gap-2x">AI Chapter</div>
                        <h2 className="text-h2 text-fw-medium text-clr-primary">
                            AI services & solutions to accelerate business growth
                        </h2>
                    </div>
                </div>
            </section>
            <section className="ai-chapter-services">
                <div className="container container--py">
                    <div className="ai-chapter-services__wrapper">
                        <div className="content">
                            <h3 className="name text-p1 text-fw-medium gap-1x">Leverage GenAI</h3>
                            <p className="description text-p4 text-fw-regular text-clr-secondary gap-1x">
                                Empower your business with the transformative potential of
                                generative AI, driving innovation and efficiency in your product
                                landscape.
                            </p>
                            <Link className="sdn-link" to="">
                                Explore
                            </Link>
                        </div>
                    </div>
                    {/* <div className="services-call-to-action">
                        <Link to="/contact/" className="sdn-cta-btn">
                            Contact us
                        </Link>
                    </div> */}
                </div>
            </section>
            <section className="ai-chapter-content">
                <div className="container container--mid-v2">
                    <div className="ai-chapter-content__text">
                        <div className="text-h1 text-fw-medium gap-2x">
                            Generative AI for Everyone
                        </div>
                        <p className="text-p2 text-clr-secondary gap-1x">
                            AI is transforming not only industries, but also the way we work and
                            live everyday.
                        </p>
                        <p className="text-p2 text-clr-secondary gap-1x">
                            Sedin collaborates globally to co-create market ready applications to
                            help our clients win in the future, by enabling innovative products,
                            faster time to market, enhanced predictability and efficiencies at
                            scale. With experience in solving leading problems, we can assist you on
                            your next AI journey.
                        </p>
                        <ul>
                            <li>High Performance Automation</li>
                            <li>Touchless Process</li>
                            <li>Customer Experience transformation</li>
                            <li>Application Modernization</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="ai-chapter-content">
                <div className="container container--mid-v2">
                    <div className="ai-chapter-content__text">
                        <div className="text-h1 text-fw-medium gap-2x">
                            Explore AI across Core Business Process
                        </div>
                        <p className="text-p2 text-clr-secondary gap-1x">
                            We cater to our client’s needs in every industry; providing them with
                            the best GenAI solutions to help unlock value from existing processes.
                        </p>

                        <ul>
                            <li>Gen AI for Lifesciences</li>
                            <li>Gen AI for Consumer and Retail</li>
                            <li>Gen AI for Banking</li>
                            <li>Gen AI for Supply Chain</li>
                            <li>Gen AI for HR and Media</li>
                            <li>Gen AI for Sales and Marketing</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="home-partners">
                <div className="container container--mid-v2">
                    <SedinPartners />
                </div>
            </section>
            <section className="about-contact">
                <div className="container container--last">
                    <ContactUsBlock />
                </div>
            </section>
        </Layout>
    );
};

export default AIChapterPage;
