import React from "react";
import { StaticImage } from "gatsby-plugin-image";
const SedinPartners = () => {
    return (
        <>
            <div className="text">
                <h2 className="text-h1 text-fw-medium text-clr-primary gap-3x">
                    We deliver accelerated & authorized global expertise.
                </h2>
                <p className="text-p2 text-fw-regular text-clr-secondary gap-4x">
                    In our continued effort to unlock next-gen solutions for your business, we’ve
                    partnered with leading technology providers.
                </p>
            </div>
            <div className="partners-grid">
                <div className="partner-logo">
                    <StaticImage
                        src="../images/partners/microsoft.png"
                        placeholder="blurred"
                        alt="partners"
                        loading="lazy"
                    />
                </div>
                <div className="partner-logo">
                    <StaticImage
                        src="../images/partners/ibm.png"
                        placeholder="blurred"
                        alt="partners"
                        loading="lazy"
                    />
                </div>
                <div className="partner-logo">
                    <StaticImage
                        src="../images/partners/aws.png"
                        placeholder="blurred"
                        alt="partners"
                        loading="lazy"
                    />
                </div>
                <div className="partner-logo">
                    <StaticImage
                        src="../images/partners/salesforce.png"
                        placeholder="blurred"
                        alt="partners"
                        loading="lazy"
                    />
                </div>
                <div className="partner-logo">
                    <StaticImage
                        src="../images/partners/shopify-plus.png"
                        placeholder="blurred"
                        alt="partners"
                        loading="lazy"
                    />
                </div>
                <div className="partner-logo">
                    <StaticImage
                        src="../images/partners/tableau.png"
                        placeholder="blurred"
                        alt="partners"
                        loading="lazy"
                    />
                </div>
                <div className="partner-logo">
                    <StaticImage
                        src="../images/partners/opentext.png"
                        placeholder="blurred"
                        alt="partners"
                        loading="lazy"
                    />
                </div>
                <div className="partner-logo">
                    <StaticImage
                        src="../images/partners/ui-path.png"
                        placeholder="blurred"
                        alt="partners"
                        loading="lazy"
                    />
                </div>
                <div className="partner-logo">
                    <StaticImage
                        src="../images/partners/newgen.jpg"
                        placeholder="blurred"
                        alt="partners"
                        loading="lazy"
                    />
                </div>
            </div>
        </>
    );
};

export default SedinPartners;
